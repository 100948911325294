@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.consentContainer {
  background-color: $gb_grey_800;
  margin-bottom: 3.125rem;
  padding: 5px 0.9375px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.consentLocator {
  padding-top: 5.375rem;
  padding-bottom: 5.375rem;

  @include screen-sm {
    padding-top: 12.375rem;
    padding-bottom: 12.375rem;
  }
}

.consentBox {
  background-color: $gb_white;
  width: 100%;
  max-width: 43.75rem;
  padding: 1.25rem 1.5625rem;
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 1.25rem;
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
    line-height: 1.625rem;
  }

  div {
    margin-bottom: 2.1875rem;

    p {
      font-size: 0.9375rem;
      line-height: 1.5rem;
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
      padding: 0 !important;
      display: inline !important;
      white-space: nowrap;

      & > span:first-child {
        padding-right: 0 !important;
      }
    }
    a::before {
      display: none;
    }
  }

  @include screen-md {
    padding: 2.5rem 2.8125rem;

    h3 {
      font-size: 1.625rem;
      line-height: 1.875rem;
    }
  }
}

.consentButton {
  width: 100%;

  @include screen-sm {
    width: auto;
    place-self: flex-end;
  }
}
